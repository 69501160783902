import React, { useState } from 'react';
import { Label, Legend, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

export default function DonutChart(props) {
    const { data, field, value, title } = props;
    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, coin, balance, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';


        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`$ ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };
    const centerLabel = data.map((s) => parseFloat(s.val)).reduce((partialSum, a) => partialSum + a, 0).toFixed(3);
    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart width={300} height={300}>
                <Pie
                    dataKey={value}
                    data={data}
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    onMouseEnter={onPieEnter}
                    cx={"50%"}
                    cy={"50%"}
                    fill="#8884d8"
                    innerRadius={60}
                    outerRadius={90}
                    label
                >
                    <Label
                        value={"$ " + centerLabel} position="center" style={{ fontSize: 18 }} className='label-top' fontSize='27px'
                    />
                </Pie>
                <Legend payload={
                    data.map(
                        (item, index) => ({
                            id: item.coin,
                            type: "square",
                            value: `${item.balance} ${item.coin}`,
                            color: item.fill
                        })
                    )
                } />
            </PieChart>
        </ResponsiveContainer>
    );
};