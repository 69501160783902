import { Box, Button, Container } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
    const navigate = useNavigate();
    return <Container maxWidth="sm">
        <Box height={"100vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
            <img src={process.env.PUBLIC_URL + "images/404.gif"} className="w-100" />
            <Button variant="contained" onClick={() => navigate(-1)} color="primary">
                Go Back
            </Button>
        </Box>
    </Container>
}