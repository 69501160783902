import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchOrderRequest } from "../../store/order/order.actions";
import OrderList from "./Order.list";
import { ArrowDownwardRounded } from "@material-ui/icons";
import TitleBar from "../base/TitleBar";
import { useLocation } from "react-router-dom";

function Order() {
    const location = useLocation();
    const [mode, setMode] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if (location.pathname) {
            let type = location.pathname.replace("/order/", "");
            setMode(type);
            if (type === "buy" || type === "sell") {
                dispatch(fetchOrderRequest(location.pathname.replace("/order/", "")));
            }
        }
    }, [location])

    return (
        <div className="mt-2">
            <TitleBar heading={mode + " Order"} secondary={"Order"}
                icon={<ArrowDownwardRounded />} />
            <Grid container spacing={2} className="my-2">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <OrderList />
                </Grid>
            </Grid>
        </div>
    );
}

export default Order;
