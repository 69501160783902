import { Box, Card, CardContent, CardHeader, Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { AccountBoxSharp, AccountCircle, Edit, Email, Fingerprint, HighlightOff, HourglassEmpty, OpenInBrowser, PermIdentity, PhoneAndroid } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserRequest, updateKYCRequest } from '../../store/user/user.actions';
import { getCurrentUser } from '../../store/user/user.selector';
import FileDrop from '../base/FileDrop';
import TitleBar from '../base/TitleBar';
import { getAccessToken } from '../../store/authentication/auth.selector';
import { decodeToken } from '../../helpers/utility';

export default function ProfileContainer() {
    const dispatch = useDispatch();
    const [isEdit, setEdit] = useState(false);

    useEffect(() => {
        dispatch(fetchUserRequest())
    }, [])

    const { currentUser, accessToken } = useSelector((state) => {
        return {
            currentUser: getCurrentUser(state),
            accessToken: getAccessToken(state),
        }
    }, []);

    const user = decodeToken(accessToken);

    return (
        <div>
            <TitleBar heading="Profile Information" secondary={"Profile Information"}
                icon={<AccountCircle />} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card elevation={0}>
                        <CardHeader title={"Personal Information"} />
                        <Divider />
                        <CardContent>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <AccountBoxSharp />
                                    </ListItemAvatar>
                                    <ListItemText primary={currentUser.fullName} secondary="Name" />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Fingerprint />
                                    </ListItemAvatar>
                                    <ListItemText primary={currentUser.memberId} secondary="Member #" />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Email />
                                    </ListItemAvatar>
                                    <ListItemText primary={currentUser.email} secondary="Email" />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemAvatar>
                                        <PhoneAndroid />
                                    </ListItemAvatar>
                                    <ListItemText primary={currentUser.country + " " + currentUser.mobileNumber} secondary="Mobile Number" />
                                </ListItem>
                                <Divider />
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card elevation={0}>
                        <CardHeader title={"KYC Information"}
                            action={currentUser && currentUser.kycId && !currentUser.isKYCVerified && !isEdit ?
                                <Edit className='mt-2' color='secondary' onClick={() => setEdit(true)} /> :
                                isEdit ? <HighlightOff className='mt-2' color='error' onClick={() => setEdit(false)} /> :
                                    <></>
                            }
                        />
                        <Divider />
                        {
                            currentUser && currentUser.kycId && !isEdit ?
                                <CardContent>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <PermIdentity />
                                            </ListItemAvatar>
                                            <ListItemText primary={currentUser.kycId} secondary="KYC Document" />
                                        </ListItem>
                                        <Divider />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Fingerprint />
                                            </ListItemAvatar>
                                            <ListItemText primary={currentUser.kycNo} secondary="KYC No" />
                                        </ListItem>
                                        <Divider />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <HourglassEmpty />
                                            </ListItemAvatar>
                                            <ListItemText primary={<Chip label={currentUser.isKYCVerified ? "Approved" : "Pending"}
                                                style={{
                                                    color: "white",
                                                    background: currentUser.isKYCVerified ? "green" : "goldenrod"
                                                }}
                                            />} secondary="Status" />
                                        </ListItem>
                                        <Divider />
                                    </List>
                                    <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                        <img src={currentUser.kycPath} width={250} />
                                    </Box>
                                </CardContent>

                                :
                                <CardContent>
                                    <FileDrop
                                        containerName="kyc"
                                        canUpload={true}
                                        onClose={() => setEdit(false)}
                                        onUpload={(data) => {
                                            dispatch(updateKYCRequest(data[0]));
                                            setEdit(false);
                                        }}
                                        items={["PAN Card", "Aadhar Card", "Voter Id", "Driving License", "Cancelled Cheque", "Bank Passbook", "Passport", "Contract Document", "Company's PAN"]}
                                    />
                                </CardContent>
                        }
                    </Card>
                </Grid>
            </Grid>
        </div >
    );
}

