import { all } from 'redux-saga/effects';
import { AuthSaga } from './authentication/auth.sagas';
import { UserSaga } from './user/user.sagas';
import { TransactionSaga } from './transaction/transaction.sagas';
import { WhitelistSaga } from './whitelist/whitelist.saga';
import { WithdrawSaga } from './withdraw/withdraw.saga';
import { OrderSaga } from './order/order.sagas';

export default function* rootSaga() {
    yield all([
        AuthSaga(),
        UserSaga(),
        TransactionSaga(),
        WhitelistSaga(),
        WithdrawSaga(),
        OrderSaga()
    ]);
}