import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IsNullOrEmpty, getToken } from '../../helpers/utility';
import logo from "../../images/logo.png";
import Footer from '../base/Footer';
import SignUp from './SignUpPage';

export default function SignupContainer() {
    let token = getToken();
    const navigate = useNavigate();
    if (!IsNullOrEmpty(token)) {
        return navigate("/");
    }
    return (
        <div className='text-center'>
            <div className='outer-div'>
                <img src={logo} height={128} />
                <SignUp />
            </div>
            <Footer />
        </div>
    );
}



