export const API_URL = process.env.REACT_APP_API_URL;

export function getOrderUrl(type) {
    return API_URL + '/order?orderType=' + type;
}

export function createOrderUrl(code) {
    return API_URL + `/coin/order?code=${code}`;
}


