import HttpHelper from "../../helpers/httpHelper";
import { getPriceAPIUrl } from "./transaction.uri";

class TransactionAPI {
    static async GetPrices() {
        let url = getPriceAPIUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }
}

export default TransactionAPI;
