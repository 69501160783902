import { Avatar, Box, Button, Card, CardContent, CardHeader, Container, Divider } from '@material-ui/core';
import { Close, MobileFriendly } from '@material-ui/icons';
import { RecaptchaVerifier, linkWithPhoneNumber, signInWithPhoneNumber } from 'firebase/auth';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { defaultAuth } from '../../helpers/firebase/config';
import { decodeToken, setToken } from '../../helpers/utility';
import { loginUserSuccess } from '../../store/authentication/auth.actions';
import { getAccessToken } from '../../store/authentication/auth.selector';
import UserAPI from '../../store/user/user.api';
import VerifyPin from './verifyPin';

export default function OTPVerifyPhone(props) {
  const dispatch = useDispatch();
  const [isSent, setSent] = useState(false);
  const [final, setfinal] = useState("");
  const { accessToken } = useSelector((state) => {
    return {
      accessToken: getAccessToken(state),
    }
  })

  const user = decodeToken(accessToken);

  const signin = () => {
    if (!user.mobileNumber || user.mobileNumber.length < 10) return;
    let verify = new RecaptchaVerifier(defaultAuth,
      "recaptcha-container"
    );

    linkWithPhoneNumber(defaultAuth.currentUser, user.mobileNumber, verify)
      .then((result) => {
        setfinal(result);
        setSent(true);
      })
      .catch((err) => {
        signInWithPhoneNumber(defaultAuth, user.mobileNumber, verify)
          .then((result) => {
            setfinal(result);
            setSent(true);
          })
          .catch((err) => {
            Swal.fire({
              title: "Unable to send OTP",
              text: err,
              icon: "error"
            }).then((d) => {
              if (d.isConfirmed) {
                window.location.reload();
              }
            })
          });
      });
  };

  return (
    <Container maxWidth="sm" className='mt-4 pt-4'>
      <Card elevation={10}>
        <CardHeader
          avatar={<Avatar>
            <MobileFriendly />
          </Avatar>}
          title={"Mobile Verification"} subheader="6 Digit OTP"
          action={<Close onClick={() => props.onClose()} />}
        />
        <Divider />
        <CardContent>
          {
            isSent ? <>
              <h5 className="text-center my-2 text-secondary">
                OTP has been sent to your registered Phone Number <b className='text-primary'>{user.mobileNumber}</b>. Please Verify
              </h5>
              <VerifyPin
                type="phone"
                onComplete={(otp) => {
                  if (otp === null || final === null) return;
                  final
                    .confirm(otp)
                    .then((result) => {
                      setToken(result._tokenResponse.idToken);
                      UserAPI.VerifyPhoneOTP().then((x) => {
                        dispatch(loginUserSuccess(x));
                        Swal.fire({
                          icon: "success",
                          title: "Phone Number Verification Done",
                          showConfirmButton: true,
                          confirmButtonText: "Close"
                        }).then((x) => {
                          if (x.isConfirmed) {
                            props.onComplete();
                          }
                        })
                      })
                    })
                    .catch((err) => {
                      Swal.fire({
                        title: "Invalid OTP",
                        text: err,
                        icon: "error"
                      })
                    });
                }}
                onClose={() => {
                  props.onClose()
                }}
              />
            </> :
              <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <div id="recaptcha-container"></div>
                <Button variant='contained' color="secondary" onClick={signin}>
                  Send OTP
                </Button>
              </Box>
          }

        </CardContent>
      </Card>
    </Container>
  );
}
