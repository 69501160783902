import { Avatar, Box, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Switch } from "@material-ui/core";
import { AccountBalance, AccountBalanceWallet, CalendarToday, CalendarViewDay, Close, DeleteForever, Description, Edit, FileCopy, Fingerprint, ListAlt, Visibility } from "@material-ui/icons";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import toastr from "toastr";
import _ from "underscore";
import { IsNullOrEmpty } from "../../helpers/utility";
import { deleteWhitelistRequest, statusWhitelistRequest } from "../../store/whitelist/whitelist.action";
import { getWhitelists } from "../../store/whitelist/whitelist.selector";
import Loader from "../base/loader";
import VerifyPin from "../verify/verifyPin";

function WhitelistList(props) {
    const { onEdit } = props;
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState("");
    const [datum, setDatum] = useState({});
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState("");

    const { whitelists, isLoading } = useSelector((state) => {
        return {
            whitelists: getWhitelists(state),
            isLoading: state.WhitelistReducer.isLoading
        }
    });

    useEffect(() => {
        if (selectedItem) {
            let z = _.find(whitelists, x => x.id === selectedItem);
            setDatum(z);
        }
        else {
            setDatum({});
        }
    }, [selectedItem])


    let filteredList = whitelists;
    if (!filteredList) {
        filteredList = [];
    }

    filteredList = _.sortBy(filteredList, "createdAt").reverse();

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    return (
                        <>
                            <Visibility
                                color="secondary"
                                onClick={() => setSelectedItem(value)}
                            />
                            &nbsp;
                            <DeleteForever
                                color='error'
                                onClick={() => {
                                    Swal.fire({
                                        icon: "question",
                                        title: "Confirm delete",
                                        text: "Are you confirm to delete the wallet",
                                        showCancelButton: true,
                                        confirmButtonText: "Proceed",
                                        cancelButtonText: "Close"
                                    }).then((st) => {
                                        if (st.isConfirmed) {
                                            setOpen(true);
                                            setSelectedItem(value);
                                        }
                                    })
                                }} />
                            &nbsp;
                            <Edit
                                color="action"
                                onClick={() => onEdit(value)}
                            />
                        </>
                    );
                }
            }
        });
    };

    const statusRenderer = () => {
        return ({
            name: 'id',
            label: 'Status',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    const item = _.find(whitelists, s => s.id === value);
                    return (
                        <Switch checked={item.status === "Approved"} onChange={(e) => {
                            Swal.fire({
                                icon: "question",
                                title: "Confirm change",
                                text: "Are you confirm to change the status of the wallet",
                                showCancelButton: true,
                                confirmButtonText: "Proceed",
                                cancelButtonText: "Close"
                            }).then((st) => {
                                if (st.isConfirmed) {
                                    setOpen(true);
                                    setSelectedItem(value);
                                    setStatus(item.status === "Approved" ? "Rejected" : "Approved");
                                }
                            })
                        }} />
                    );
                }
            }
        });
    };


    const columns = [
        {
            name: 'address',
            label: 'Address',
            options: {
                filter: false,
                sort: true,
            }
        },

        {
            name: 'createdAt',
            label: 'Created On',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'activatedAt',
            label: 'Activated On',
            options: {
                filter: false,
                sort: false,
            }
        },

        statusRenderer(),
        actionRenderer()
    ];

    const data = filteredList.map((item) => {
        return {
            minCoins: item.minCoins,
            maxCoins: item.maxCoins,
            coolingPeriod: item.coolingPeriod,
            createdAt: moment.unix(item.createdAt._seconds).format("LLL"),
            activatedAt: moment.unix(item.createdAt._seconds).add(item.coolingPeriod, "seconds").format("LLL"),
            address: item.address,
            id: item.id,
            status: item.status
        }
    })

    return (
        <div>
            <Dialog open={open}>
                <DialogContent>
                    <VerifyPin
                        onClose={() => {
                            setOpen(false);
                        }}
                        isNotVerify={true}
                        onComplete={(code) => {
                            if (!status) {
                                dispatch(deleteWhitelistRequest(
                                    {
                                        code: code,
                                        id: selectedItem
                                    }
                                ))
                            }
                            else {
                                dispatch(statusWhitelistRequest({
                                    code: code,
                                    id: selectedItem,
                                    status: status
                                }))
                            }
                            setOpen(false);
                            setSelectedItem("");
                            setDatum({});
                            setStatus("");
                        }} />
                </DialogContent>
            </Dialog>
            {
                isLoading && <Loader />
            }
            {
                datum.id &&
                <Dialog maxWidth="md" fullWidth open={!IsNullOrEmpty(selectedItem) && datum.id && !open}>
                    <DialogTitle>
                        Whitelist Information
                        <Close className="float-right mt-2" onClick={() => {
                            setSelectedItem("");
                        }} />
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <AccountBalance color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Min Coins' primary={`${datum.minCoins} ${datum.tokenSymbol || datum.coinType}`} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <AccountBalanceWallet color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Max Coins' primary={`${datum.maxCoins} ${datum.tokenSymbol || datum.coinType}`} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <ListAlt color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Status' primary={datum.status} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Description color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Address' primary={datum.address} primaryTypographyProps={{
                                            className: "overflow-text"
                                        }} />
                                        {
                                            navigator && navigator.clipboard &&
                                            <ListItemSecondaryAction>
                                                <FileCopy color="secondary" onClick={() => {
                                                    navigator.clipboard.writeText(datum.address);
                                                    toastr.success("Copied to clipboard");
                                                }} />
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Fingerprint color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Nickname' primary={datum.nickName} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <CalendarToday color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Created On' primary={moment.unix(datum.createdAt._seconds).format("lll")} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <CalendarViewDay color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Activated On' primary={moment.unix(datum.updatedAt._seconds).add(datum.coolingPeriod, "seconds").format("lll")} />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
            <Grid container spacing={2} className="my-2">
                {
                    data.length === 0 && !isLoading ?
                        <Grid xs={12} sm={12}>
                            {
                                data.length === 0 &&
                                <Box display={"flex"} height="60vh" flexDirection={"column"} justifyContent={"center"} alignItems={"center"} className="my-2">
                                    <img src={process.env.PUBLIC_URL + "/images/noData.svg"} width={"250"} />
                                </Box>
                            }
                        </Grid> :

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MUIDataTable
                                title={'Whitelist List'}
                                data={data}
                                columns={columns}
                                options={{
                                    filterType: 'checkbox',
                                    selectableRows: 'none',
                                    rowsPerPageOptions: [10],
                                }}
                            />
                        </Grid>
                }
            </Grid>
        </div>
    );
}

export default WhitelistList;
