import HttpHelper from "../../helpers/httpHelper";
import { createOrderUrl, getOrderUrl } from "./order.uri";

class OrderAPI {
    static async GetOrders(type) {
        let url = getOrderUrl(type);
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async CreateOrder(req) {
        let url = createOrderUrl(req.code);
        delete req.code;
        return HttpHelper.httpRequest(url, 'POST', {}, req);
    }
}

export default OrderAPI;
