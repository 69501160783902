import { action } from "typesafe-actions";
import { WithdrawActionTypes } from "./withdraw.model";

export const fetchWithdrawRequest = (req) => action(WithdrawActionTypes.FETCH_WITHDRAW_REQUEST, req);
export const fetchWithdrawSuccess = (res) => action(WithdrawActionTypes.FETCH_WITHDRAW_SUCCESS, res);
export const fetchWithdrawFailure = (err) => action(WithdrawActionTypes.FETCH_WITHDRAW_FAILURE, err);

export const transferCoinRequest = (req) => action(WithdrawActionTypes.TRANSFER_COINS_REQUEST, req);
export const transferCoinSuccess = (res) => action(WithdrawActionTypes.TRANSFER_COINS_SUCCESS, res);
export const transferCoinFailure = (err) => action(WithdrawActionTypes.TRANSFER_COINS_FAILURE, err);

