import { Button, Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhitelistRequest } from "../../store/whitelist/whitelist.action";
import WhitelistList from "./Whitelist.list";
import ManageWhitelist from "./Whitelist.Add";
import { getWhitelists } from "../../store/whitelist/whitelist.selector";
import _ from "underscore";
import TitleBar from "../base/TitleBar";
import { AccountBalanceWallet } from "@material-ui/icons";

function Whitelist() {
    const dispatch = useDispatch();
    const [mode, setMode] = useState("list");
    const [selectedItem, setSelectedIdItem] = useState("");
    const [isEdit, setEdit] = useState(false);

    const { whitelists, isLoading } = useSelector((state) => {
        return {
            whitelists: getWhitelists(state),
            isLoading: state.WhitelistReducer.isLoading
        }
    });

    useEffect(() => {
        dispatch(fetchWhitelistRequest())
    }, [])

    useEffect(() => {
        setMode("list");
    }, [whitelists])

    return (
        <div className="mt-2">
            <TitleBar heading="Whitelist" secondary={"Whitelist"}
                icon={<AccountBalanceWallet />}
                action={
                    <Button variant="contained" color="primary" onClick={() => {
                        setMode(mode === "list" ? "add" : "list");
                        setSelectedIdItem("");
                        setEdit(false);
                    }} className="float-right" size="small">
                        {mode === "list" ? "Add Whitelist" : "List All"}
                    </Button>
                }
            />
            <Grid container spacing={2} className="my-2">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {
                        mode === "list" ? <WhitelistList onEdit={(item) => {
                            setEdit(true);
                            setSelectedIdItem(item);
                            setMode("Edit")
                        }} /> : <ManageWhitelist isEdit={isEdit} selectedItem={_.find(whitelists, s => s.id === selectedItem)} />
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default Whitelist;
