import { WhitelistActionTypes, whitelistInitialState } from './whitelist.model';

const reducer = (state = whitelistInitialState, action) => {
    switch (action.type) {
        case WhitelistActionTypes.FETCH_WHITELIST_REQUEST:
        case WhitelistActionTypes.CREATE_WHITELIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case WhitelistActionTypes.FETCH_WHITELIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                whitelists: action.payload
            };

        case WhitelistActionTypes.CREATE_WHITELIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                whitelists: state.whitelists.concat(action.payload)
            };

        case WhitelistActionTypes.STATUS_WHITELIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                whitelists: state.whitelists.map((s) => {
                    if (action.payload === s.id) {
                        s.status = action.payload.status;
                    }
                    return s;
                })
            };

        case WhitelistActionTypes.UPDATE_WHITELIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                whitelists: state.whitelists.map((s) => {
                    if (action.payload.id === s.id) {
                        s = action.payload;
                    }
                    return s;
                })
            };

        case WhitelistActionTypes.DELETE_WHITELIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                whitelists: state.whitelists.filter((s) => {
                    return action.payload.id !== s.id;
                })
            };

        case WhitelistActionTypes.FETCH_WHITELIST_FAILURE:
        case WhitelistActionTypes.CREATE_WHITELIST_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export { reducer as WhitelistReducer };