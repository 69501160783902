export const OrderActionTypes = {
  FETCH_ORDER_REQUEST: '@@order/FETCH_ORDER_REQUEST',
  FETCH_ORDER_SUCCESS: '@@order/FETCH_ORDER_SUCCESS',
  FETCH_ORDER_FAILURE: '@@order/FETCH_ORDER_FAILURE',

  CREATE_ORDER_REQUEST: '@@order/CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: '@@order/CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE: '@@order/CREATE_ORDER_FAILURE',
}

export const orderInitialState = {
  orders: [],
  prices: [],
  isLoading: false
}