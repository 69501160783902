import { OrderActionTypes, orderInitialState } from './order.model';

const reducer = (state = orderInitialState, action) => {
    switch (action.type) {
        case OrderActionTypes.FETCH_ORDER_REQUEST:
        case OrderActionTypes.CREATE_ORDER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case OrderActionTypes.FETCH_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                orders: action.payload
            };

        case OrderActionTypes.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                orders: [action.payload, ...state.orders]
            };

        case OrderActionTypes.FETCH_ORDER_FAILURE:
        case OrderActionTypes.CREATE_ORDER_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export { reducer as OrderReducer };