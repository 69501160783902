import { call, put, takeEvery } from 'redux-saga/effects';
import * as UserActions from './user.actions';
import UserAPI from './user.api';
import { UserActionTypes } from './user.model';
import Swal from "sweetalert2";
import { loginUserSuccess } from '../authentication/auth.actions';

export function* handleRegisterUser(action) {
  try {
    const response = yield call(
      UserAPI.RegisterUser,
      action.payload
    );
    yield put(UserActions.registerUserSuccess(response));
    Swal.fire({
      title: "Registration successful",
      text: "Your Member ID is " + response.memberId + ".Please login to your account now",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((x) => {
      if (x.isDismissed) {
        document.location.href = "/login";
      }
    })
  } catch (e) {
    yield put(UserActions.registerUserFailure(e));
  }
}

export function* handleCurrentUser() {
  try {
    const response = yield call(UserAPI.GetCurrentUser);
    yield put(UserActions.fetchUserSuccess(response));
  } catch (e) {
    yield put(UserActions.fetchUserFailure(e));
  }
}

export function* handleCoinBalance() {
  try {
    const response = yield call(UserAPI.GetCoinBalance);
    yield put(UserActions.fetchCoinBalanceSuccess(response));
  } catch (e) {
    yield put(UserActions.fetchCoinBalanceFailure(e));
  }
}

export function* handleUpdateToken(action) {
  try {
    const response = yield call(UserAPI.UpdateTokens, action.payload);
    yield put(UserActions.updateTokenSuccess(response));
  } catch (e) {
    yield put(UserActions.updateTokenFailure(e));
  }
}

export function* handleBNBTokenBalance(action) {
  try {
    let response = yield call(UserAPI.GetBNBWalletAddressBal, action.payload);
    response.address = action.payload.tokenAddress;
    yield put(UserActions.getTokenBNBBalSuccess(response));
  } catch (e) {
    yield put(UserActions.getTokenBNBBalFailure(e));
  }
}

export function* handleUpdateKyc(action) {
  try {
    let response = yield call(UserAPI.UpdateKycInformation, action.payload);
    yield put(UserActions.updateKYCSuccess(response.data));
    yield put(loginUserSuccess(response.token));
    Swal.fire({
      title: "KYC Update",
      text: "Admin will approve soon.",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    })
  } catch (e) {
    yield put(UserActions.updateKYCFailure(e));
  }
}

export function* UserSaga() {
  yield takeEvery(UserActionTypes.REGISTER_USER_REQUEST, handleRegisterUser);
  yield takeEvery(UserActionTypes.FETCH_USER_REQUEST, handleCurrentUser);
  yield takeEvery(UserActionTypes.FETCH_BALANCE_REQUEST, handleCoinBalance);
  yield takeEvery(UserActionTypes.UPDATE_TOKEN_REQUEST, handleUpdateToken);
  yield takeEvery(UserActionTypes.GET_TOKEN_BAL_REQUEST, handleBNBTokenBalance);
  yield takeEvery(UserActionTypes.UPDATE_KYC_REQUEST, handleUpdateKyc);
}

