import { TransactionActionTypes, transactionInitialState } from './transaction.model';

const reducer = (state = transactionInitialState, action) => {
    switch (action.type) {
        case TransactionActionTypes.FETCH_PRICES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case TransactionActionTypes.FETCH_PRICES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                prices: action.payload
            };

        case TransactionActionTypes.FETCH_PRICES_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export { reducer as TransactionReducer };