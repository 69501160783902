import { action } from "typesafe-actions";
import { UserActionTypes } from "./user.model";

export const registerUserRequest = (req) => action(UserActionTypes.REGISTER_USER_REQUEST, req);
export const registerUserSuccess = (res) => action(UserActionTypes.REGISTER_USER_SUCCESS, res);
export const registerUserFailure = (err) => action(UserActionTypes.REGISTER_USER_FAILURE, err);

export const fetchUserRequest = (req) => action(UserActionTypes.FETCH_USER_REQUEST, req);
export const fetchUserSuccess = (res) => action(UserActionTypes.FETCH_USER_SUCCESS, res);
export const fetchUserFailure = (err) => action(UserActionTypes.FETCH_USER_FAILURE, err);

export const fetchCoinBalanceRequest = () => action(UserActionTypes.FETCH_BALANCE_REQUEST);
export const fetchCoinBalanceSuccess = (res) => action(UserActionTypes.FETCH_BALANCE_SUCCESS, res);
export const fetchCoinBalanceFailure = (err) => action(UserActionTypes.FETCH_BALANCE_FAILURE, err);

export const updateTokenRequest = (req) => action(UserActionTypes.UPDATE_TOKEN_REQUEST, req);
export const updateTokenSuccess = (res) => action(UserActionTypes.UPDATE_TOKEN_SUCCESS, res);
export const updateTokenFailure = (err) => action(UserActionTypes.UPDATE_TOKEN_FAILURE, err);

export const getTokenBNBBalRequest = (req) => action(UserActionTypes.GET_TOKEN_BAL_REQUEST, req);
export const getTokenBNBBalSuccess = (res) => action(UserActionTypes.GET_TOKEN_BAL_SUCCESS, res);
export const getTokenBNBBalFailure = (err) => action(UserActionTypes.GET_TOKEN_BAL_FAILURE, err);

export const updateKYCRequest = (req) => action(UserActionTypes.UPDATE_KYC_REQUEST, req);
export const updateKYCSuccess = (res) => action(UserActionTypes.UPDATE_KYC_SUCCESS, res);
export const updateKYCFailure = (err) => action(UserActionTypes.UPDATE_KYC_FAILURE, err);





