import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Montserrat"',
      'sans-serif'
    ].join(','),
    fontWeightBold: 700,
    fontWeightLight: 400,
    fontWeightMedium: 600,
    fontWeightRegular: 500
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none"
      }
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: "aliceblue"
      }
    }
  },
  palette: {
    primary: {
      main: '#8f18a2',
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: '#16d1e6',
      contrastText: "#FFFFFF"
    },
  },
});

export default theme;
