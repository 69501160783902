import { call, put, takeEvery } from 'redux-saga/effects';
import * as OrderActions from './order.actions';
import OrderAPI from './order.api';
import { OrderActionTypes } from './order.model';
import Swal from 'sweetalert2';
import { fetchCoinBalanceRequest } from '../user/user.actions';

export function* handleFetchOrder(action) {
    try {
        const response = yield call(OrderAPI.GetOrders, action.payload);
        yield put(OrderActions.fetchOrderSuccess(response));
    } catch (e) {
        yield put(OrderActions.fetchOrderFailure(e));
    }
}

export function* handleCreateOrder(action) {
    try {
        const response = yield call(OrderAPI.CreateOrder, action.payload);
        yield put(OrderActions.createOrderSuccess(response));
        yield put(fetchCoinBalanceRequest());
        Swal.fire({
            title: "Create Order",
            text: `Order Created Successfully`,
            icon: "success"
        })
    } catch (e) {
        yield put(OrderActions.createOrderFailure(e));
    }
}

export function* OrderSaga() {
    yield takeEvery(OrderActionTypes.FETCH_ORDER_REQUEST, handleFetchOrder);
    yield takeEvery(OrderActionTypes.CREATE_ORDER_REQUEST, handleCreateOrder);
}
