import { action } from "typesafe-actions";
import { OrderActionTypes } from "./order.model";

export const fetchOrderRequest = (req) => action(OrderActionTypes.FETCH_ORDER_REQUEST, req);
export const fetchOrderSuccess = (res) => action(OrderActionTypes.FETCH_ORDER_SUCCESS, res);
export const fetchOrderFailure = (err) => action(OrderActionTypes.FETCH_ORDER_FAILURE, err);

export const createOrderRequest = (req) => action(OrderActionTypes.CREATE_ORDER_REQUEST, req);
export const createOrderSuccess = (res) => action(OrderActionTypes.CREATE_ORDER_SUCCESS, res);
export const createOrderFailure = (err) => action(OrderActionTypes.CREATE_ORDER_FAILURE, err);


