export const API_URL = process.env.REACT_APP_API_URL;

export function getWhitelistUrl() {
    return API_URL + '/whitelist';
}

export function createWhitelistUrl(code) {
    return API_URL + '/whitelist?code=' + code;
}

export function updateWhitelistUrl(code, id) {
    return API_URL + '/whitelist/' + id + '?code=' + code;
}

export function deleteWhitelistUrl(code, id) {
    return API_URL + '/whitelist/' + id + '?code=' + code;
}

export function updateStatusWhitelistUrl(code, id, status) {
    return API_URL + '/whitelist/' + id + '?code=' + code + "&status=" + status;
}