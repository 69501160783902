import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './assets/css/toastr.min.css';
import ProtectedRoute from './helpers/PrivateRoute';
import DashboardContainer from './pages/dashboard/Dashboard.Container';
import LoginContainer from './pages/login/Login.Container';
import ProfileContainer from './pages/profile/Profile.Container';
import SignupContainer from './pages/signup/Signup.Container';
import WhitelistContainer from './pages/whitelist/Whitelist.Container';
import WithdrawContainer from './pages/withdraw/Withdraw.Container';
import OrderContainer from './pages/order/Order.Container';
import OTPVerify from './pages/verify/otp-verify';
import theme from './theme';
import PageNotFound from './pages/base/PageNotFound';

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<LoginContainer />} />
          <Route exact path="/signup" element={<SignupContainer />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <DashboardContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfileContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/verify"
            element={
              <ProtectedRoute>
                <OTPVerify />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/whitelist"
            element={
              <ProtectedRoute>
                <WhitelistContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/withdraw"
            element={
              <ProtectedRoute>
                <WithdrawContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/order/:type"
            element={
              <ProtectedRoute>
                <OrderContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path='*'
            element={
              <PageNotFound />
            } />
        </Routes>
      </BrowserRouter>
    </MuiThemeProvider >
  );
}
