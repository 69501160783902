import HttpHelper from "../../helpers/httpHelper";
import { createWhitelistUrl, deleteWhitelistUrl, getWhitelistUrl, updateStatusWhitelistUrl, updateWhitelistUrl } from "./whitelist.uri";

class WhitelistAPI {
    static async GetWhitelists() {
        let url = getWhitelistUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }
    static async CreateWhitelist(req) {
        let url = createWhitelistUrl(req.code);
        return HttpHelper.httpRequest(url, 'POST', {}, req.data);
    }

    static async UpdateWhitelist(req) {
        let url = updateWhitelistUrl(req.code, req.id);
        return HttpHelper.httpRequest(url, 'PUT', {}, req.data);
    }

    static async DeleteWhitelist(req) {
        let url = deleteWhitelistUrl(req.code, req.id);
        return HttpHelper.httpRequest(url, 'DELETE', {}, req.data);
    }

    static async UpdateStatusWhitelist(req) {
        let url = updateStatusWhitelistUrl(req.code, req.id, req.status);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }
}

export default WhitelistAPI;
