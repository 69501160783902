import React from "react";
import { FileCopy } from "@material-ui/icons";
import { Avatar, Card, CardHeader } from "@material-ui/core";
import { useSelector } from "react-redux";
import toastr from "toastr";
import { getCurrentUser } from "../../../store/user/user.selector";
import { getLogo } from "../../../helpers/utility";

function WalletList(props) {
    const { onChange, selectedItem, coinBalance } = props;

    const { user } = useSelector((state) => {
        return {
            user: getCurrentUser(state),
        }
    });

    return (
        <div>
            {
                user.wallets && user.wallets.map((s) => {
                    return <Card className="mb-2 cursor" elevation={s.currency === selectedItem ? 10 : 0} onClick={() => onChange(s.currency)}>
                        <CardHeader
                            title={s.currency}
                            subheader={coinBalance[s.currency] || 0}
                            action={
                                <FileCopy color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(s.address);
                                        toastr.success("Copied Address to clipboard");
                                    }} />
                            }
                            avatar={<Avatar src={getLogo(s.currency).logo} >
                            </Avatar>} />
                    </Card>
                })
            }
        </div>
    );
}

export default WalletList;
