import { Poll } from "@material-ui/icons";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { calculateData } from "../../../helpers/utility";
import { getCoinPrices } from "../../../store/transaction/transaction.selector";
import DonutChart from "../../base/DonutChart";
export const pageSize = 9;

function WalletChart(props) {
    const { coinBalance } = props;

    const { prices } = useSelector((state) => {
        return {
            prices: getCoinPrices(state)
        }
    });

    return (
        <>
            {
                prices && prices.length > 0 &&
                <Card elevation={0}>
                    <CardHeader avatar={<Poll color="primary" />} title="Wallets" subheader="Statistics">
                    </CardHeader>
                    <CardContent style={{ position: "relative" }}>
                        <DonutChart data={calculateData(prices, coinBalance)} field="coin" value="val" title="Coins" />
                    </CardContent>
                </Card>
            }
        </>
    );
}

export default WalletChart;
