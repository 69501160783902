import { WithdrawActionTypes, withdrawInitialState } from './withdraw.model';

const reducer = (state = withdrawInitialState, action) => {
    switch (action.type) {
        case WithdrawActionTypes.FETCH_WITHDRAW_REQUEST:
        case WithdrawActionTypes.TRANSFER_COINS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case WithdrawActionTypes.TRANSFER_COINS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                withdraws: [action.payload, ...state.withdraws]
            };

        case WithdrawActionTypes.FETCH_WITHDRAW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                withdraws: action.payload
            };

        case WithdrawActionTypes.FETCH_WITHDRAW_FAILURE:
        case WithdrawActionTypes.TRANSFER_COINS_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export { reducer as WithdrawReducer };