import { Box, Chip, Dialog, DialogContent, DialogTitle, Divider, Grid } from "@material-ui/core";
import { Close, Visibility } from "@material-ui/icons";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "underscore";
import { IsNullOrEmpty, getTransactionUrl } from "../../helpers/utility";
import { getWithdraws } from "../../store/withdraw/withdraw.selector";
import Loader from "../base/loader";
import WithdrawDetail from "./WithdrawDetail";

function WithdrawList(props) {
    const [selectedItem, setSelectedItem] = useState("");
    const [datum, setDatum] = useState({});

    const { withdraws, isLoading } = useSelector((state) => {
        return {
            withdraws: getWithdraws(state),
            isLoading: state.WithdrawReducer.isLoading
        }
    });

    useEffect(() => {
        if (selectedItem) {
            let z = _.find(withdraws, x => x.id === selectedItem);
            setDatum(z);
        }
        else {
            setDatum({});
        }
    }, [selectedItem])

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Info',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let user = withdraws.filter((item) => {
                        return item.id === value;
                    });
                    return (
                        <Visibility
                            color="secondary"
                            onClick={() => setSelectedItem(value)}
                        />
                    );
                }
            }
        });
    };

    const hashRenderer = () => {
        return ({
            name: 'id',
            label: 'Transaction Hash',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    let item = _.find(withdraws, s => s.id === value)
                    return (
                        item.transactionHash ? <a target='_blank' href={getTransactionUrl(item.coinType) + item.transactionHash}>Click</a> : <></>
                    );
                }
            }
        });
    };

    const statusRenderer = () => {
        return ({
            name: 'status',
            label: 'Status',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value}
                            style={{
                                color: "white",
                                background: value === "Approved" ? "green" : "red"
                            }}
                        />
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: 'createdDate',
            label: 'Transaction Time',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'coins',
            label: 'Transfered Coins',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        {
            name: 'transactionCharge',
            label: 'Transaction Charge',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        hashRenderer(),
        statusRenderer(),
        actionRenderer()
    ];

    const data = withdraws.map((item) => {
        return {
            id: item.id,
            createdDate: moment.unix(item.createdAt._seconds).format("lll"),
            transactionCharge: item.transactionCharge + ' ' + (item.tokenSymbol ? item.tokenSymbol : item.coinType),
            coins: item.coins + ' ' + (item.tokenSymbol ? item.tokenSymbol : item.coinType),
            status: item.status,
            fromAddress: item.fromAddress,
            hash: item.transactionHash,
        };
    });

    return (
        <div>
            {
                datum.id &&
                <Dialog maxWidth="md" fullWidth open={!IsNullOrEmpty(selectedItem) && datum.id}>
                    <DialogTitle>
                        Withdraw Information
                        <Close className="float-right mt-2" onClick={() => {
                            setSelectedItem("");
                        }} />
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <WithdrawDetail withdraw={datum} />
                    </DialogContent>
                </Dialog>
            }
            {
                isLoading && <Loader />
            }
            <Grid container spacing={2} className="my-2">
                {
                    data.length === 0 && !isLoading ?
                        <Grid xs={12} sm={12}>
                            {
                                data.length === 0 &&
                                <Box display={"flex"} height="60vh" flexDirection={"column"} justifyContent={"center"} alignItems={"center"} className="my-2">
                                    <img src={process.env.PUBLIC_URL + "/images/noData.svg"} width={"250"} />
                                </Box>
                            }
                        </Grid> :

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MUIDataTable
                                title={'Withdraw List'}
                                data={data}
                                columns={columns}
                                options={{
                                    filterType: 'checkbox',
                                    selectableRows: 'none',
                                    rowsPerPageOptions: [10],
                                }}
                            />
                        </Grid>
                }
            </Grid>
        </div>
    );
}

export default WithdrawList;
