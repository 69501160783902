import { action } from "typesafe-actions";
import { WhitelistActionTypes } from "./whitelist.model";

export const fetchWhitelistRequest = (req) => action(WhitelistActionTypes.FETCH_WHITELIST_REQUEST, req);
export const fetchWhitelistSuccess = (res) => action(WhitelistActionTypes.FETCH_WHITELIST_SUCCESS, res);
export const fetchWhitelistFailure = (err) => action(WhitelistActionTypes.FETCH_WHITELIST_FAILURE, err);

export const createWhitelistRequest = (req) => action(WhitelistActionTypes.CREATE_WHITELIST_REQUEST, req);
export const createWhitelistSuccess = (res) => action(WhitelistActionTypes.CREATE_WHITELIST_SUCCESS, res);
export const createWhitelistFailure = (err) => action(WhitelistActionTypes.CREATE_WHITELIST_FAILURE, err);

export const updateWhitelistRequest = (req) => action(WhitelistActionTypes.UPDATE_WHITELIST_REQUEST, req);
export const updateWhitelistSuccess = (res) => action(WhitelistActionTypes.UPDATE_WHITELIST_SUCCESS, res);
export const updateWhitelistFailure = (err) => action(WhitelistActionTypes.UPDATE_WHITELIST_FAILURE, err);

export const deleteWhitelistRequest = (req) => action(WhitelistActionTypes.DELETE_WHITELIST_REQUEST, req);
export const deleteWhitelistSuccess = (res) => action(WhitelistActionTypes.DELETE_WHITELIST_SUCCESS, res);
export const deleteWhitelistFailure = (err) => action(WhitelistActionTypes.DELETE_WHITELIST_FAILURE, err);

export const statusWhitelistRequest = (req) => action(WhitelistActionTypes.STATUS_WHITELIST_REQUEST, req);
export const statusWhitelistSuccess = (res) => action(WhitelistActionTypes.STATUS_WHITELIST_SUCCESS, res);
export const statusWhitelistFailure = (err) => action(WhitelistActionTypes.STATUS_WHITELIST_FAILURE, err);
