import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCda_Y-6ohFe2Fii05RBDpx3tn5L52ZyTA",
    authDomain: "test-wallet-payment.firebaseapp.com",
    projectId: "test-wallet-payment",
    storageBucket: "test-wallet-payment.appspot.com",
    messagingSenderId: "267335161300",
    appId: "1:267335161300:web:804c800418ee7e82953241"
};

const app = initializeApp(firebaseConfig);
const defaultAuth = getAuth(app);
const defaultProvider = new GoogleAuthProvider();
export { defaultAuth, defaultProvider, app };