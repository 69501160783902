export const WhitelistActionTypes = {
    FETCH_WHITELIST_REQUEST: '@@whitelist/FETCH_WHITELIST_REQUEST',
    FETCH_WHITELIST_SUCCESS: '@@whitelist/FETCH_WHITELIST_SUCCESS',
    FETCH_WHITELIST_FAILURE: '@@whitelist/FETCH_WHITELIST_FAILURE',

    CREATE_WHITELIST_REQUEST: '@@whitelist/CREATE_WHITELIST_REQUEST',
    CREATE_WHITELIST_SUCCESS: '@@whitelist/CREATE_WHITELIST_SUCCESS',
    CREATE_WHITELIST_FAILURE: '@@whitelist/CREATE_WHITELIST_FAILURE',

    UPDATE_WHITELIST_REQUEST: '@@whitelist/UPDATE_WHITELIST_REQUEST',
    UPDATE_WHITELIST_SUCCESS: '@@whitelist/UPDATE_WHITELIST_SUCCESS',
    UPDATE_WHITELIST_FAILURE: '@@whitelist/UPDATE_WHITELIST_FAILURE',

    STATUS_WHITELIST_REQUEST: '@@whitelist/STATUS_WHITELIST_REQUEST',
    STATUS_WHITELIST_SUCCESS: '@@whitelist/STATUS_WHITELIST_SUCCESS',
    STATUS_WHITELIST_FAILURE: '@@whitelist/STATUS_WHITELIST_FAILURE',

    DELETE_WHITELIST_REQUEST: '@@whitelist/DELETE_WHITELIST_REQUEST',
    DELETE_WHITELIST_SUCCESS: '@@whitelist/DELETE_WHITELIST_SUCCESS',
    DELETE_WHITELIST_FAILURE: '@@whitelist/DELETE_WHITELIST_FAILURE',
}

export const whitelistInitialState = {
    whitelists: [],
    isLoading: false
}