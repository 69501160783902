import { Divider, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchWithdrawRequest } from "../../store/withdraw/withdraw.action";
import WithdrawList from "./Withdraw.list";
import { ArrowDownwardRounded } from "@material-ui/icons";
import TitleBar from "../base/TitleBar";

function Withdraw() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchWithdrawRequest())
    }, [])

    return (
        <div className="mt-2">
              <TitleBar heading="Withdraw" secondary={"Withdraw"}
                icon={<ArrowDownwardRounded />} />
            <Divider />
            <Grid container spacing={2} className="my-2">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <WithdrawList />
                </Grid>
            </Grid>
        </div>
    );
}

export default Withdraw;
