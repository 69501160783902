export const API_URL = process.env.REACT_APP_API_URL;

export function getAgentsUrl() {
    return API_URL + '/agents';
}

export function get2FASetup() {
    return API_URL + '/member/2fa/setup';
}

export function verify2FAUrl() {
    return API_URL + '/member/2fa/verify';
}

export function verifyMobileUrl() {
    return API_URL + '/member/verify/mobile';
}

export function updateTokensUrl() {
    return API_URL + '/member/tokens/update';
}

export function updateKYCUrl() {
    return API_URL + '/member/kyc';
}

export function getBNBBalanceUrl(req) {
    return API_URL + '/token/bnb/balance/' + req.contractAddress + "?address=" + req.address;
}


