import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { AccountBalance, AccountBalanceWallet, AccountBox, CalendarToday, CalendarViewDay, Description, Email, FileCopy, Fingerprint, FlipToBackOutlined, Map, OpenInBrowser, OpenInNew, Phone, ZoomOutMapOutlined } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import toastr from "toastr";
import { getBlockChainUrl, getTransactionUrl } from '../../helpers/utility';

export default function WithdrawDetail(props) {
    const { withdraw } = props;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <AccountBalance color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Transaction Coins' primary={`${withdraw.tokenSymbol || withdraw.coinType} ${withdraw.coins}`} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <AccountBalanceWallet color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Transaction Charge' primary={`${withdraw.tokenSymbol || withdraw.coinType} ${withdraw.transactionCharge}`} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <Fingerprint color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Transaction ID' primary={withdraw.id} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <FileCopy color="primary" onClick={() => {
                                    navigator.clipboard.writeText(withdraw.id);
                                    toastr.success("Copied to clipboard");
                                }} />
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <ZoomOutMapOutlined color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Status' primary={withdraw.status} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <CalendarToday color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Created On' primary={moment.unix(withdraw.createdAt._seconds).format("lll")} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <CalendarViewDay color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Last Modified On' primary={moment.unix(withdraw.updatedAt._seconds).format("lll")} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <OpenInBrowser color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='From Address' primary={withdraw.fromAddress} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <a target='_blank' href={getBlockChainUrl(withdraw.coinType) + withdraw.fromAddress}>
                                    <OpenInNew color="primary" />
                                </a>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <OpenInBrowser color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='To Address' primary={withdraw.toAddress} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <a target='_blank' href={getBlockChainUrl(withdraw.coinType) + withdraw.toAddress}>
                                    <OpenInNew color="primary" />
                                </a>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
            {
                withdraw.contractAddress &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <List disablePadding>
                        <ListItem disableGutters>
                            <ListItemAvatar>
                                <Avatar>
                                    <FlipToBackOutlined color="primary" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText secondary='Token Address' primary={withdraw.contractAddress} />
                            {
                                navigator && navigator.clipboard &&
                                <ListItemSecondaryAction>
                                    <a target='_blank' href={getBlockChainUrl(withdraw.coinType) + withdraw.contractAddress}>
                                        <OpenInNew color="primary" />
                                    </a>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                    </List>
                </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <Fingerprint color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Transaction Hash' primary={withdraw.transactionHash} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <a target='_blank' href={getTransactionUrl(withdraw.coinType) + withdraw.transactionHash}>
                                    <OpenInNew color="primary" />
                                </a>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <Fingerprint color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Transaction Charge Hash' primary={withdraw.transactionChargeHash} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <a target='_blank' href={getTransactionUrl(withdraw.coinType) + withdraw.transactionChargeHash}>
                                    <OpenInNew color="primary" />
                                </a>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
}
