import { call, put, takeEvery } from 'redux-saga/effects';
import * as WhitelistActions from './whitelist.action';
import WhitelistAPI from './whitelist.api';
import { WhitelistActionTypes } from './whitelist.model';
import Swal from 'sweetalert2';

export function* handleFetchWhitelist(action) {
    try {
        const response = yield call(WhitelistAPI.GetWhitelists, action.payload);
        yield put(WhitelistActions.fetchWhitelistSuccess(response));
    } catch (e) {
        yield put(WhitelistActions.fetchWhitelistFailure(e));
    }
}

export function* handleCreateWhitelist(action) {
    try {
        const response = yield call(WhitelistAPI.CreateWhitelist, action.payload);
        yield put(WhitelistActions.createWhitelistSuccess(response));
        Swal.fire({
            title: "Whitelist",
            text: `Wallet whitelisted successfully`,
            icon: "success"
        })
    } catch (e) {
        yield put(WhitelistActions.createWhitelistFailure(e));
    }
}

export function* handleUpdateWhitelist(action) {
    try {
        const response = yield call(WhitelistAPI.UpdateWhitelist, action.payload);
        yield put(WhitelistActions.updateWhitelistSuccess(response));
        Swal.fire({
            title: "Whitelist",
            text: `Wallet whitelist updated successfully`,
            icon: "success"
        })
    } catch (e) {
        yield put(WhitelistActions.updateWhitelistFailure(e));
    }
}

export function* handleUpdateStatusWhitelist(action) {
    try {
        const response = yield call(WhitelistAPI.UpdateStatusWhitelist, action.payload);
        yield put(WhitelistActions.statusWhitelistSuccess(action.payload));
        Swal.fire({
            title: "Whitelist",
            text: `Wallet whitelist status updated successfully`,
            icon: "success"
        });
        yield put(WhitelistActions.fetchWhitelistRequest());
    } catch (e) {
        yield put(WhitelistActions.statusWhitelistFailure(e));
    }
}

export function* handleDeleteWhitelist(action) {
    try {
        const response = yield call(WhitelistAPI.DeleteWhitelist, action.payload);
        yield put(WhitelistActions.deleteWhitelistSuccess(action.payload));
        Swal.fire({
            title: "Whitelist",
            text: `Wallet whitelist deleted successfully`,
            icon: "success"
        });
    } catch (e) {
        yield put(WhitelistActions.deleteWhitelistFailure(e));
    }
}

export function* WhitelistSaga() {
    yield takeEvery(WhitelistActionTypes.FETCH_WHITELIST_REQUEST, handleFetchWhitelist);
    yield takeEvery(WhitelistActionTypes.CREATE_WHITELIST_REQUEST, handleCreateWhitelist);
    yield takeEvery(WhitelistActionTypes.DELETE_WHITELIST_REQUEST, handleDeleteWhitelist);
    yield takeEvery(WhitelistActionTypes.UPDATE_WHITELIST_REQUEST, handleUpdateWhitelist);
    yield takeEvery(WhitelistActionTypes.STATUS_WHITELIST_REQUEST, handleUpdateStatusWhitelist);
}
