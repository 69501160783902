import HttpHelper from "../../helpers/httpHelper";
import { getWithdrawUrl, getTransferUrl } from "./withdraw.uri";

class WithdrawAPI {
    static async GetWithdraws(status) {
        let url = getWithdrawUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async TransferCoins(req) {
        let url = getTransferUrl(req.code);
        delete req.code;
        return HttpHelper.httpRequest(url, 'POST', {}, req);
    }
}

export default WithdrawAPI;
