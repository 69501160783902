import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchCoinBalanceRequest } from '../user/user.actions';
import * as TransactionActions from './transaction.actions';
import TransactionAPI from './transaction.api';
import { TransactionActionTypes } from './transaction.model';

export function* handleFetchPrices() {
    try {
        const response = yield call(TransactionAPI.GetPrices);
        yield put(TransactionActions.fetchPricesSuccess(response));
        yield put(fetchCoinBalanceRequest());
    } catch (e) {
        yield put(TransactionActions.fetchPricesFailure(e));
    }
}

export function* TransactionSaga() {
    yield takeEvery(TransactionActionTypes.FETCH_PRICES_REQUEST, handleFetchPrices);
}
