import { UserActionTypes } from './user.model';
require('dotenv').config();

export const initialState = {
    user: {},
    isLoading: false,
    coinBalance: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.REGISTER_USER_REQUEST:
        case UserActionTypes.FETCH_USER_REQUEST:
        case UserActionTypes.UPDATE_KYC_REQUEST:
        case UserActionTypes.UPDATE_TOKEN_REQUEST:
        case UserActionTypes.FETCH_BALANCE_REQUEST:
        case UserActionTypes.GET_TOKEN_BAL_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case UserActionTypes.GET_TOKEN_BAL_SUCCESS:
            let tokens = state.user && state.user.tokens && state.user.tokens.map((t) => {
                if (t.address === action.payload.address) {
                    t.balance = action.payload.balance;
                }
                return t;
            })
            return {
                ...state,
                isLoading: false,
                user: {
                    ...state.user,
                    tokens: tokens
                }
            };

        case UserActionTypes.UPDATE_KYC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload
            };

        case UserActionTypes.UPDATE_TOKEN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: {
                    ...state.user,
                    tokens: action.payload
                }
            };

        case UserActionTypes.FETCH_BALANCE_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                coinBalance: action.payload,
            });
        }

        case UserActionTypes.REGISTER_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case UserActionTypes.FETCH_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                user: action.payload
            };
        }

        case UserActionTypes.FETCH_BALANCE_FAILURE:
        case UserActionTypes.REGISTER_USER_FAILURE:
        case UserActionTypes.GET_TOKEN_BAL_FAILURE:
        case UserActionTypes.UPDATE_KYC_FAILURE:
        case UserActionTypes.FETCH_USER_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as UserReducer };