import { Button, Dialog, Container, DialogContent, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createWhitelistRequest, updateWhitelistRequest } from "../../store/whitelist/whitelist.action";
import { IsNullOrEmpty, allowedCoins } from "../../helpers/utility";
import VerifyPin from '../verify/verifyPin';

function ManageWhitelist(props) {
    const { isEdit, selectedItem } = props;
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [address, setAddress] = useState("");

    const [minCoins, setMinCoins] = useState("");
    const [maxCoins, setMaxCoins] = useState("");
    const [isValidated, setValidated] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (isEdit && selectedItem.id) {
            setName(selectedItem.nickName);
            setType(selectedItem.coinType);
            setAddress(selectedItem.address);
            setMinCoins(selectedItem.minCoins);
            setMaxCoins(selectedItem.maxCoins);
        }
    }, [isEdit, selectedItem])

    const validate = () => {
        return !IsNullOrEmpty(type) &&
            !IsNullOrEmpty(name) &&
            !IsNullOrEmpty(address) &&
            !IsNullOrEmpty(minCoins) &&
            !IsNullOrEmpty(maxCoins) &&
            parseFloat(minCoins) > 0 &&
            parseFloat(maxCoins) > 0
    }

    const handleCreate = () => {
        if (validate()) {
            setOpen(true);
        }
        else {
            setValidated(true);
        }
    }
    return (
        <div className="mt-2">
            <Dialog open={open}>
                <DialogContent>
                    <VerifyPin
                        onClose={() => {
                            setOpen(false);
                        }}
                        isNotVerify={true}
                        onComplete={(code) => {
                            if (isEdit) {
                                dispatch(updateWhitelistRequest(
                                    {
                                        code: code,
                                        id: selectedItem.id,
                                        data: {
                                            "nickName": name,
                                            "minCoins": minCoins,
                                            "maxCoins": maxCoins
                                        }
                                    }
                                ))
                            }
                            else {
                                dispatch(createWhitelistRequest(
                                    {
                                        code: code,
                                        data: {
                                            "nickName": name,
                                            "coinType": type,
                                            "address": address,
                                            "minCoins": minCoins,
                                            "maxCoins": maxCoins
                                        }
                                    }
                                ));
                            }
                            setOpen(false);
                        }} />
                </DialogContent>
            </Dialog>
            <Container maxWidth="sm">
                <FormControl variant='outlined' fullWidth error={isValidated && IsNullOrEmpty(type)}>
                    <InputLabel id="demo-simple-select-label">Coin Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        disabled={isEdit}
                        label="Coin Type"
                        className='my-2'
                        onChange={(e) => setType(e.target.value)}
                    >
                        {
                            allowedCoins.map((s) => {
                                return <MenuItem value={s}>{s}</MenuItem>
                            })
                        }
                    </Select>
                    {
                        isValidated && IsNullOrEmpty(type) &&
                        <FormHelperText>
                            Coin Type should be valid
                        </FormHelperText>
                    }

                </FormControl>
                <TextField
                    label="Address"
                    fullWidth
                    value={address}
                    disabled={isEdit}
                    variant="outlined"
                    className='my-2'
                    onChange={(e) => {
                        setAddress(e.target.value)
                    }}
                    error={isValidated && IsNullOrEmpty(address)}
                    helperText={isValidated && IsNullOrEmpty(address) ? "Address should be valid" : ""}
                />
                <TextField
                    label="Min Coins"
                    fullWidth
                    value={minCoins}
                    variant="outlined"
                    type='number'
                    className='my-2'
                    onChange={(e) => {
                        setMinCoins(e.target.value)
                    }}
                    error={isValidated && IsNullOrEmpty(minCoins)}
                    helperText={isValidated && (IsNullOrEmpty(minCoins) || parseFloat(minCoins) < 0.001) ? "Min Coins should be valid" : ""}
                />
                <TextField
                    label="Max Coins"
                    fullWidth
                    value={maxCoins}
                    variant="outlined"
                    type='number'
                    className='my-2'
                    onChange={(e) => {
                        setMaxCoins(e.target.value)
                    }}
                    error={isValidated && IsNullOrEmpty(maxCoins)}
                    helperText={isValidated && (IsNullOrEmpty(maxCoins) || parseFloat(maxCoins) < 0.001) ? "Max Coins should be valid" : ""}
                />
                <TextField
                    label="Nick name"
                    fullWidth
                    value={name}
                    variant="outlined"
                    className='my-2'
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    error={isValidated && IsNullOrEmpty(name)}
                    helperText={isValidated && IsNullOrEmpty(name) ? "Name should be valid" : ""}
                />
                <Button variant='contained' fullWidth color="primary" onClick={() => {
                    handleCreate();
                }}>
                    Save Whitelist
                </Button>
            </Container>
        </div>
    );
}

export default ManageWhitelist;
