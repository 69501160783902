import { Box, Button, Card, CardActions, CardContent, CircularProgress, Container } from '@material-ui/core';
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { loginUserSuccess } from "../../store/authentication/auth.actions";
import UserAPI from "../../store/user/user.api";

const VerifyPin = (props) => {
    const dispatch = useDispatch();
    const { onClose, onComplete, type, isNotVerify } = props;
    const [otp, setOtp] = useState('');
    const [isLoading, setLoading] = useState(false);

    const handleConfirm = () => {
        if (isNotVerify) {
            onComplete(otp);
        }
        else {
            setLoading(true);
            if (type === "email") {
                UserAPI.VerifyEmailOTP(otp).then((x) => {
                    dispatch(loginUserSuccess(x));
                    onComplete();
                }).catch((e) => {
                    Swal.fire({
                        icon: "error",
                        title: "Invalid PIN",
                        text: "Please enter the correct PIN",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: "Close"
                    }).then((x) => {

                    })
                }).finally(() => {
                    setLoading(false);
                })
            }
            else if (type === "phone") {
                onComplete(otp);
                setLoading(false);
            }
            else {
                UserAPI.Verify2FACode(otp).then((x) => {
                    onComplete();
                }).catch((e) => {
                    Swal.fire({
                        icon: "error",
                        title: "Invalid PIN",
                        text: "Please enter the correct PIN",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: "Close"
                    }).then((x) => {

                    })
                }).finally(() => {
                    setLoading(false);
                })
            }
        }
    }

    return (
        <Container maxWidth="xs">
            {
                isLoading && <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                    <CircularProgress />
                    <p className="text-primary">Loading...</p>
                </Box>
            }
            <Card elevation={0}>
                <h4 className="p-2 text-center text-underline">Enter PIN</h4>
                <CardContent className='d-flex justify-content-center'>

                    <OTPInput
                        value={otp}
                        inputStyle="otp-input"
                        onChange={setOtp}
                        inputType='password'
                        numInputs={6}
                        renderSeparator={<span className="mr-1"></span>}
                        renderInput={(props) => <input {...props} />}
                    />
                </CardContent>
                <CardActions className='d-flex justify-content-center'>
                    <Button onClick={() => handleConfirm()} variant='contained' disabled={otp.length !== 6}>
                        Verify
                    </Button>
                    <Button onClick={() => onClose()} variant='contained' color="secondary">
                        Close
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};

export default VerifyPin;
