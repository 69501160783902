import { call, put, takeEvery } from 'redux-saga/effects';
import * as WithdrawActions from './withdraw.action';
import WithdrawAPI from './withdraw.api';
import { WithdrawActionTypes } from './withdraw.model';
import Swal from 'sweetalert2';
import { fetchCoinBalanceRequest } from '../user/user.actions';

export function* handleFetchWithdraw(action) {
    try {
        const response = yield call(WithdrawAPI.GetWithdraws, action.payload);
        yield put(WithdrawActions.fetchWithdrawSuccess(response));
    } catch (e) {
        yield put(WithdrawActions.fetchWithdrawFailure(e));
    }
}

export function* handleTransferCoins(action) {
    try {
        const response = yield call(WithdrawAPI.TransferCoins, action.payload);
        yield put(WithdrawActions.transferCoinSuccess(response));
        yield put(fetchCoinBalanceRequest());
        Swal.fire({
            title: "Transfer Coins",
            text: `${action.payload.coins} ${action.payload.coinType} transferred successfully to ${action.payload.toAddress}`,
            icon: "success"
        })
    } catch (e) {
        yield put(WithdrawActions.transferCoinFailure(e));
    }
}

export function* WithdrawSaga() {
    yield takeEvery(WithdrawActionTypes.FETCH_WITHDRAW_REQUEST, handleFetchWithdraw);
    yield takeEvery(WithdrawActionTypes.TRANSFER_COINS_REQUEST, handleTransferCoins);
}
