import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPricesRequest } from "../../store/transaction/transaction.actions";
import { getCoinPrices } from "../../store/transaction/transaction.selector";
import { fetchUserRequest } from "../../store/user/user.actions";
import { getCoinBalance, getCurrentUser } from "../../store/user/user.selector";
import { fetchWhitelistRequest } from "../../store/whitelist/whitelist.action";
import TitleBar from "../base/TitleBar";
import WalletCard from "./wallets/walletCard";
import WalletChart from "./wallets/walletChart";
import WalletList from "./wallets/walletList";
import { BarChart } from "@material-ui/icons";

function Dashboard() {
    const dispatch = useDispatch();

    const [currentBalance, setCurrentBalance] = useState(0);
    const [selectedItem, setSelectedItem] = useState("");

    const { userInfo, coinBalance, prices } = useSelector((state) => {
        return {
            userInfo: getCurrentUser(state),
            coinBalance: getCoinBalance(state),
            prices: getCoinPrices(state)
        }
    });

    useEffect(() => {
        if (selectedItem) {
            setCurrentBalance(coinBalance[selectedItem] || 0);
        }
    }, [selectedItem])


    useEffect(() => {
        dispatch(fetchUserRequest());
        dispatch(fetchPricesRequest());
        dispatch(fetchWhitelistRequest());
    }, [])

    return (
        <div className="mt-2">
            <TitleBar heading="Dashboard" secondary={"Dashboard"}
                icon={<BarChart />} />
            <h2>Welcome , <b>{userInfo.fullName}</b></h2>
            <Divider />

            <Grid container spacing={2} className="my-2">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <WalletList coinBalance={coinBalance} selectedItem={selectedItem} onChange={(item) => setSelectedItem(item)} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {
                        selectedItem ? <WalletCard
                            wallets={userInfo.wallets}
                            selectedItem={selectedItem}
                            onClose={() => setSelectedItem("")}
                            coinBalance={coinBalance}
                            currentBalance={currentBalance} /> :
                            <WalletChart coinBalance={coinBalance} />
                    }
                </Grid>

            </Grid>
        </div>
    );
}

export default Dashboard;
