import { signInWithPopup } from 'firebase/auth';
import { call, put, takeEvery } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import { defaultAuth, defaultProvider } from '../../helpers/firebase/config';
import * as AuthActions from './auth.actions';
import { AuthenticationActionTypes } from './auth.model';
import AuthAPI from './auth.api';
import { setToken } from '../../helpers/utility';

export function* handleLogin(action) {
    let response = null;
    try {
        response = yield call(signInWithPopup, defaultAuth, defaultProvider);
        if (response.user.email === action.payload) {
            setToken(response._tokenResponse.idToken);
            const response1 = yield call(AuthAPI.Login);
            yield put(AuthActions.loginUserSuccess(response1));
        }
        else {
            Swal.fire({
                title: "Access Denied",
                text: "Please login from your registered email ",
                icon: "error"
            })
        }

    }
    catch (e) {
        Swal.fire({
            title: "Login Failure",
            text: "Email not Exists, Please try again with different email",
            icon: "error"
        });
        yield put(AuthActions.loginUserFailure(e));
    }
}

export function* AuthSaga() {
    yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}
