export const UserActionTypes = {
  REGISTER_USER_REQUEST: "@@User/REGISTER_USER_REQUEST",
  REGISTER_USER_SUCCESS: "@@User/REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "@@User/REGISTER_USER_FAILURE",

  FETCH_USER_REQUEST: "@@User/FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS: "@@User/FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE: "@@User/FETCH_USER_FAILURE",

  UPDATE_KYC_REQUEST: "@@User/UPDATE_KYC_REQUEST",
  UPDATE_KYC_SUCCESS: "@@User/UPDATE_KYC_SUCCESS",
  UPDATE_KYC_FAILURE: "@@User/UPDATE_KYC_FAILURE",
  
  FETCH_BALANCE_REQUEST: '@@user/FETCH_BALANCE_REQUEST',
  FETCH_BALANCE_SUCCESS: '@@user/FETCH_BALANCE_SUCCESS',
  FETCH_BALANCE_FAILURE: '@@user/FETCH_BALANCE_FAILURE',

  UPDATE_TOKEN_REQUEST: '@@user/UPDATE_TOKEN_REQUEST',
  UPDATE_TOKEN_SUCCESS: '@@user/UPDATE_TOKEN_SUCCESS',
  UPDATE_TOKEN_FAILURE: '@@user/UPDATE_TOKEN_FAILURE',

  GET_TOKEN_BAL_REQUEST: '@@user/GET_TOKEN_BAL_REQUEST',
  GET_TOKEN_BAL_SUCCESS: '@@user/GET_TOKEN_BAL_SUCCESS',
  GET_TOKEN_BAL_FAILURE: '@@user/GET_TOKEN_BAL_FAILURE',
};

